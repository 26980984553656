/* stylelint-disable at-rule-no-unknown */
/* stylelint-disable csstree/validator */
@use "./color.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Roboto+Slab:wght@200;300;400;600&display=swap");

@mixin displayed($theme: row) {
  display: flex;
  flex-direction: $theme;
}

.form-container {
  .form-name {
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: -0.18px;
    color: #888;
  }

  .forma {
    margin-top: 20px;
    display: flex;
    grid-gap: 32px;
    gap: 32px;

    .input-text {
      width: 40%;
      font-family: "Montserrat", sans-serif;
      padding: 12px;
      border-radius: 4px;
      border: 1px solid #e8e8e8;
      background-color: #fff;
      font-size: 1rem;
      letter-spacing: -0.15px;
      color: #121212;
    }

    .input-submit {
      cursor: pointer;
      width: 20%;
      padding: 12px;
      border-radius: 3px;
      background-color: color.$second;
      border: none;
      letter-spacing: 0.5px;
      color: #fff;
      font-size: 0.813rem;
      font-weight: 700;
      font-family: "Roboto Slab", serif;
    }

    .submit-warning {
      font-family: "Montserrat", sans-serif;
    }
  }
}
