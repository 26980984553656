/* stylelint-disable at-rule-no-unknown */
/* stylelint-disable csstree/validator */
@use "./color.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Roboto+Slab:wght@200;300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

@mixin displayed($theme: row) {
  display: flex;
  flex-direction: $theme;
}

.header {
  @include displayed;

  justify-content: space-between;
  padding: 0 4%;
  align-items: center;

  .main-header {
    @include displayed;

    .header-title {
      font-family: "Montserrat", sans-serif;
      color: color.$second;
    }

    .nav-bar {
      display: flex;
      justify-content: center;

      .nav-var-list {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin: 0;
        color: #000;

        .link {
          text-decoration: none;
          font-family: "Montserrat", sans-serif;
          color: color.$genre;
          font-size: 0.813rem;
        }
      }
    }
  }

  .user {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2.813rem;
    height: 2.813rem;
    border-radius: 50%;
    border: 1px solid #e8e8e8;
    background-color: #fff;

    .material-symbols-outlined {
      font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      color: color.$second;
    }
  }
}
