/* stylelint-disable at-rule-no-unknown */
/* stylelint-disable csstree/validator */
@use "./color.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Roboto+Slab:wght@200;300;400;600&display=swap");

@mixin displayed($theme: row) {
  display: flex;
  flex-direction: $theme;
}

.book {
  @include displayed;

  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: color.$main;
  padding: 2rem;
  justify-content: center;
  align-items: center;

  .book-main {
    @include displayed($theme: column);

    justify-content: center;
    width: 60%;

    .book-data {
      @include displayed($theme: column);

      align-items: flex-start;
      margin-left: 1.68rem;

      .book-category {
        margin: 0;
        size: 0.875rem;
        font-family: "Montserrat", sans-serif;
        color: color.$genre;
        opacity: 0.5;
        font-weight: 700;
      }

      .book-title {
        margin: 0;
        margin-top: 0.188rem;
        letter-spacing: -0.2px;
        font-family: "Roboto Slab", serif;
        size: 1.375rem;
        font-weight: bold;
      }

      .book-author {
        margin: 0;
        font-family: "Roboto Slab", serif;
        size: 0.875rem;
        font-weight: 300;
        color: color.$book-button;
      }
    }

    .book-actions {
      @include displayed;

      list-style: none;
      margin-left: 1.68rem;
      padding: 0;
      align-items: center;
      margin-top: 1.313rem;

      .li-buttons {
        .btn {
          border: none;
          background-color: color.$main;
          color: color.$book-button;
          font-family: "Roboto Slab", serif;
          font-size: 0.875rem;
          font-weight: 300;
          cursor: pointer;
          padding: 0;
        }

        .borders {
          border-right: solid 1px color.$book-button;
          border-left: solid 1px color.$book-button;
          padding: 0 1rem;
          margin: 0 1rem;
        }
      }
    }
  }

  .progress-circle {
    @include displayed;

    justify-content: center;
    align-items: center;
    margin-left: 19rem;

    .oval {
      width: 5.625rem;
      height: 5.625rem;
      padding: 0.63rem 0.625rem 0.75rem 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .circular-progress {
        position: absolute;
        border: solid 5px #307bbe;
        border-left-color: #e8e8e8;
        border-radius: 50%;
        width: 4.25rem;
        height: 4.25rem;
        border-style: solid;
        border-width: 5px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .progress {
      @include displayed($theme: column);

      justify-content: center;

      .average {
        width: 4.25rem;
        height: 2.438rem;
        margin: 0.063rem 0 0 0.688rem;
        font-family: "Montserrat", sans-serif;
        font-size: 2rem;
        color: color.$genre;
      }

      .state {
        width: 5rem;
        height: 1.125rem;
        margin: 0.438rem 0 0.75rem 0.75rem;
        opacity: 0.5;
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
      }
    }
  }

  .progress-divider {
    display: flex;
    align-items: center;
    width: 0.125rem;
    height: 4.375rem;
    margin: 0 2.438rem;
    background-color: #e8e8e8;
  }

  .chapter {
    @include displayed($theme: column);

    align-items: flex-start;
    justify-content: center;

    .chapter-info {
      @include displayed($theme: column);

      align-items: flex-start;

      .current-chapter {
        font-family: "Roboto Slab", serif;
        font-size: 0.813rem;
        color: color.$genre;
        font-weight: lighter;
        margin: 0;
        opacity: 0.5;
      }

      .nchapter {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        font-size: 1rem;
        color: color.$genre;
        margin: 0;
        margin-top: 0.438rem;
      }
    }

    .update {
      width: 11.5rem;
      height: 2.063rem;
      margin: 0.75rem 3.688rem 0.563rem 0;
      padding: 0.438rem 1.188rem 0.5rem 1.375rem;
      border-radius: 3px;
      border: none;
      background-color: color.$second;
      font-family: "Roboto Slab", serif;
      color: color.$main;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
  }
}
